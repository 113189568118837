@import "~bootstrap/scss/bootstrap"
@import "./basedata/variables"

*
  border-color: $pm-primary !important
  &::placeholder
    color: tint-color($pm-primary, 40%) !important

  input
    color: tint-color($pm-primary, 20%) !important

.btn-primary
  background-color: $pm-dark !important
  color: $white !important

label, h1, h2, h3, h4, h5, h6
  color: $pm-primary !important
  .text-muted
    color: tint-color($pm-primary, 20%) !important

.card .card-header h5:after
  background-color: tint-color($pm-primary, 70%) !important

.pcoded-navbar
  background-color: $pm-dark !important
  color: $white !important

  label
    color: $white !important

.pcoded-navbar .header-logo
  background: none !important

.password-rules
  padding: 8px
  list-style: none
  color: $pm-primary
  +fontDefault
  font-size: 13px
  margin-top: 8px

.label-error
  color: $red-300
  padding-top: 16px

.form-floating label
  border: 0 !important

.reason-radios
  .form-check
    margin: 16px 0
    border-bottom: 1px solid $pm-primary
    .form-check-label
      width: 100%
      padding-bottom: 8px
      padding-top: 8px
      text-transform: uppercase
    .form-check-input
      margin-top: 13px

.login-logo
    max-width: 140px

.logo-version
  font-size: 10px