@import "~bootstrap/scss/bootstrap"
@import "./../../basedata/variables"

body
    margin: 8px


.card-title
    display: flex !important
    align-items: center
    justify-content: center
.club-header
    +fontSubTitle
    text-align: center
    img
        width: 30px
        margin: 0 16px

.ticket-action
    width: 100%
    padding: 16px
    border-radius: 0

    &.red
        background-color: $red-500 !important

    &.yellow
        background-color: $yellow-500 !important

    &.purple
        background-color: $purple-500 !important

.btn-outline-primary
    border-color: $pm-primary
    color: $pm-primary

    &:hover
        border-color: $pm-primary 
        color: $white 
        background-color: $pm-primary
    &:active
        border-color: $pm-primary 
        color: $white 
        background-color: $pm-primary

.game-qr
    width: 100%
    height: 90vh
    padding: 16px
    display: flex
    align-items: center
    justify-content: center
    flex-direction: column

    img
        width: 100%
        margin-bottom: 40px

    .btn-outline-primary
        border-color: tint-color($pm-primary, 40%) !important
        color:  tint-color($pm-primary, 40%) !important

.canvas-wrap
    position: relative
.canvas-frame
    position: absolute
    z-index: 2
    width: 100%
    left: 32%
    top: 25%

    img
        width: 40%