@import "./../../basedata/variables"

.user-gates-list
  list-style: none
  max-width: 60px
  display: flex
  flex-wrap: wrap
  max-width: 150px
  padding: 0
  li
    display: inline-block

.user-trigger
  border-radius: 6px
  padding: 16px 8px
  background-color: $pm-primary
  margin-left: 16px

  +fontDefault
  text-decoration: none
  text-transform: uppercase

  &.user-not-found
    background-color: $red-500

.card .card-header h5.search-header
  margin-bottom: 16px

.btn-ghost
  background: none
  border: 1px solid
  color: $pm-primary

.table th, .table td
  border: 0
  padding-bottom: 24px
